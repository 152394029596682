<template>
  <form v-if="!preventBind" class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Arquivo para Leiloeiro</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <e-row mr v-if="lote">
        <e-col>
          <div class="sl-alert m-b">Formalização do lote #{{lote.id}} / {{lote.numeroString || lote.numero}} - Leilão #{{lote.leilao.id}} - {{lote.leilao.titulo}}</div>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <tipo-arquivo-arrematante-select ref="tipoSelect" v-model="model.tipo" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-select required :options="permissions" v-model="model.permissao" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Nome">
            <erp-input autofocus placeholder="Nome do arquivo" v-model="model.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Descrição" icon-help="Caso o arquivo seja disponibilizado ao arrematante, o mesmo verá esta descrição">
            <textarea class="sl-textarea" style="height: 80px" v-model="model.descricao"></textarea>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Validade">
            <erp-input placeholder="dd/mm/aaaa" v-model="model.validade" v-mask="'##/##/####'"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Observações internas" icon-help="Informação somente interna, não será disponibilizada para o arrematante ou comitente">
           <textarea class="sl-textarea" placeholder="Motivo de assumir e/ou entregar a propriedade" v-model="model.observacoes" rows="4"></textarea>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row v-if="!model.id" mr>
        <e-col>
          <erp-s-field
              label="Arquivo:"
          >
            <input type="file" id="file"
                   v-on:change="selectFile" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Disponível">
            <sl-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {datePtToEn} from "@/utils/date"
import {find, update, newArquivo} from "@/domain/arrematante/services/arquivos"
import SlCheckbox from "@/reuse/input/Checkbox"
import TipoArquivoArrematanteSelect from "components/arrematante/components/include/TipoArquivoArrematanteSelect"
import {fileToBase64} from "@/utils/fileToBase64"

const mockArquivo = {
  id: null,
  nome: null,
  descricao: null,
  active: true,
  arrematante: null,
  permissao: 1,
  tipo: null,
  validade: null,
  refer: null,
  observacoes: null,
  file: null
}

export default {
  name: 'BemProprietarioWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  components: {
    ErpSelect,
    TipoArquivoArrematanteSelect,
    SlCheckbox,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    // UTooltip,
  },
  props: ['id', 'arrematante', 'lote', 'options', 'router'],
  data() {
    return {
      loading: false,
      success: false,
      preventBind: true,
      model: this.getMock(),
      permissions: [
        {
          label: 'Disponível para o Leiloeiro',
          value: 1
        },
        {
          label: 'Disponível para o Leiloeiro e Junta Comercial',
          value: 2
        },
        {
          label: 'Privado, somente administração',
          value: 100
        }
      ]
    }
  },
  mounted() {
    if (this.isEditing) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {
    'model.tipo' (v) {
      this.$nextTick(() => {
        console.log(v)
        if (!this.model.nome) {
          const s = this.$refs.tipoSelect.asyncdata.find(o => o.id === v)
          if (s) {
            this.model.nome = s.nome
          }
        }
      })
    }
  },
  computed: {
    isEditing() {
      return this.id || (this.model && this.model.id)
    }
  },
  methods: {
    getMock() {
      let mock = JSON.parse(JSON.stringify(mockArquivo))
      return mock
    },
    load() {
      this.loading = true
      find(this.arrematante.id, this.isEditing)
          .then(response => {
            console.log(response)
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))
      if (data.validade) {
        data.validade = datePtToEn(data.validade)
      }
      data.arrematante = this.arrematante
      const relacoes = ['arrematante', 'tipo', 'lote']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      if (this.lote) {
        data.lote = this.lote.id
      }
      if (this.$file) {
        console.log(this.$file)
        data.data = this.$file;
        data.filename = this.$filename
      }

      return data
    },
    prepareToEdit(data) {
      const datas = ['validade']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      const relacoes = ['arrematante', 'tipo', 'lote']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      return data
    },
    selectFile (e) {
      if (e.target.files[0]) {
        this.$file = e.target.files[0];
        console.log(e.target.files[0])
        this.$filename = e.target.files[0].name;
        fileToBase64(this.$file)
            .then(file => {
              this.$file = file
            })
            .catch(error => {
              console.error(error)
            })
        return
      }
      delete this.$file
    },
    save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newArquivo
      if (this.model.id) {
        method = update
      }
      method(this.arrematante.id, data)
          .then(response => {
            this.loading = false
            console.log(response)
            const isEditing = this.isEditing
            this.model.id = response.data.id
            // this.$uloc.window.close(this.$root.wid)
            if (isEditing) {
              this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
              this.success = true
              if (type === 'andEdit') {
                this.dg()
              } else {
                this.$uloc.notify({
                  color: 'positive',
                  message: `Arquivo com ID #${response.data.id} alterado.`,
                  position: 'bottom-right',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
            } else {
              this.$uloc.window.emit(this.$root.wid, 'created', response.data)
              this.$uloc.notify({
                color: 'black',
                message: `Novo Arquivo com ID #${response.data.id} criado.`,
                position: 'bottom-right',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
              this.$uloc.window.close(this.$root.wid)
            }
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
